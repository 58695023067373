header {
  padding-block: 5rem 4rem;
}

.social-nav {
  font-size: .85714rem;
}

.social-nav > li {
  padding: 0 1.5em 0 0;
}

section.demos > .section__grid {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 0;
  margin: 0 -1rem;
}

@media (min-width: 1228px) {
  section.demos > .section__grid {
    margin: 0;
  }
}

.section__grid__anchor {
  display: block;
  position: relative;
}

.section__grid__anchor > figure {
  background-color: var(--color-bg);
  position: relative;
  transform: translate3d(0, 0, 0);
}

.section__grid__anchor > figure > figcaption {
  opacity: 0;
  background: var(--color-bg);
  text-align: center;
  color: var(--color-text-darker);
  will-change: opacity;
  padding: .25rem;
  font-size: .85714em;
  position: absolute;
  inset: auto 0 0;
}

.section__grid__anchor .FollowingEye {
  width: 20%;
  height: auto;
  opacity: 0;
  transition: opacity .2s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (hover: hover) and (pointer: fine) {
  :is(.section__grid__anchor:hover, .section__grid__anchor:focus) .section__grid__image {
    opacity: .25;
    transition: opacity .1s linear;
  }

  :is(.section__grid__anchor:hover, .section__grid__anchor:focus) .FollowingEye {
    opacity: 1;
    transition: transform .2s, opacity .1s linear;
  }

  :is(.section__grid__anchor:hover, .section__grid__anchor:focus) figcaption {
    opacity: 1;
    transition: opacity .1s linear;
  }
}

.section__grid__image {
  opacity: 1;
  background-color: var(--color-bg-lighter);
  will-change: opacity;
  display: block;
}

.oss a {
  min-height: auto;
}

.oss .icon {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  margin: .4rem .75rem 0 .25rem;
}

.oss .icon img {
  border-radius: 3px;
}

.oss .icon.emoji {
  background-color: var(--color-bg-lighter);
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

section.projects a {
  min-height: auto;
}

section.projects .icon {
  border-radius: 3px;
  flex-shrink: 0;
  margin: .2rem .5rem 0 .25rem;
}

section.projects .icon img {
  border-radius: 3px;
}

.iframe-wrapper {
  -webkit-overflow-scrolling: touch;
  display: none;
  position: fixed;
  inset: 0;
  overflow-y: scroll;
}

.iframe-wrapper > iframe {
  width: 100%;
  height: 100%;
  background: #fff;
  display: block;
}

.iframe-wrapper.-loaded + .iframe-close-button {
  transform: translate3d(0, -50%, 0);
}

.iframe-close-button {
  width: 64px;
  height: 64px;
  color: #fff;
  background: #000;
  border: none;
  border-radius: 50%;
  transition: transform .3s cubic-bezier(.1, .7, .6, .9);
  position: fixed;
  top: 50%;
  right: 1.5rem;
  transform: translate3d(200%, -50%, 0);
}

.iframe-close-button > svg {
  margin: 0 auto;
  display: block;
}

.iframe-loader {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.iframe-loader.-ready {
  display: block;
}

.iframe-loader.-show > div {
  transform: translate3d(0, 0, 0);
}

.iframe-loader.-hide > div {
  transform: translate3d(0, calc(100vh + 26px), 0);
}

.iframe-loader__layer {
  width: 100vw;
  height: 100vh;
  pointer-events: all;
  will-change: transform;
  transition: transform .4s;
  position: absolute;
  transform: translate3d(0, calc(-100vh - 26px), 0);
}

.iframe-loader__layer.--waves {
  background-color: var(--color-sec);
}

.iframe-loader__layer.--waves > svg {
  fill: var(--color-sec);
}

.iframe-loader__layer.--info {
  background-color: var(--color-main);
  justify-content: center;
  align-items: center;
  transition-delay: .2s;
  display: flex;
}

.iframe-loader__layer.--info > svg {
  fill: var(--color-main);
}

.iframe-loader__layer > .loader-wave-top, .iframe-loader__layer > .loader-wave-bottom {
  width: 100%;
  height: 26px;
  position: absolute;
}

.iframe-loader__layer > .loader-wave-top {
  bottom: 100%;
}

.iframe-loader__layer > .loader-wave-bottom {
  top: 100%;
}

.iframe-loading-text {
  text-align: center;
  color: var(--color-bg);
  opacity: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 900;
  transition: opacity .3s linear;
}

.iframe-loading-text > .title, .iframe-loading-text > .description {
  padding: .25rem 1rem;
  display: inline-block;
}

.iframe-loading-text > .title {
  background: var(--color-bg);
  color: var(--color-text);
}

.iframe-loading-text > .description {
  background: var(--color-text);
  color: var(--color-bg);
}

footer {
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  font-size: .7rem;
  display: flex;
}

@font-face {
  font-family: Droid Sans Mono;
  font-display: swap;
  src: url("droidsansmono.92020eb4.woff2") format("woff2");
}

:root {
  --color-bg: #1a1a1a;
  --color-bg-lighter: #313131;
  --color-text: #f0f0f0;
  --color-text-darker: #9a9a9a;
  --color-sec: #a1aaf3;
  --color-main: #1fffe1;
  --color-visited: #73aba3;
  --scrollbar-width: 16;
  font-size: 14px;
  line-height: 1.618;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  background-color: var(--color-main);
}

body {
  min-height: 100vh;
  background-color: var(--color-bg);
  color: var(--color-text);
  font-family: Droid Sans Mono, monospace;
}

body.-prevent-scrolling {
  padding-right: var(--scrollbar-width);
  overflow: hidden;
}

a {
  min-width: 48px;
  min-height: 48px;
  color: var(--color-main);
  outline: none;
  text-decoration: none;
  display: inline-block;
}

a .-le {
  vertical-align: top;
  align-self: center;
  display: inline-block;
  position: relative;
  overflow: hidden;
}

a .-le:before {
  content: "";
  background: var(--color-main);
  mix-blend-mode: difference;
  will-change: transform;
  transition: transform .2s;
  position: absolute;
  inset: 0;
  transform: translate3d(calc(-100% - 1px), 0, 0);
}

:is(a:active, a:hover, a:focus) .-le:before {
  transform: translate3d(0, 0, 0);
}

a:visited {
  color: var(--color-visited);
}

a:visited .-le:before {
  background: var(--color-visited);
}

a[target="_blank"] .-le {
  align-items: center;
  display: inline-flex;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1rem;
}

ul {
  list-style: none;
}

section {
  padding: 0 0 6rem;
}

svg {
  fill: currentColor;
}

canvas {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
}

.wrapper {
  max-width: calc(1200px + 2rem);
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.desc {
  color: var(--color-text-darker);
  font-size: .85714em;
}

.svg--external-icon {
  margin-left: .25em;
}

.section__grid {
  grid-gap: 1.2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  display: grid;
}

.list--decimal {
  counter-reset: list-items;
}

.list--decimal > li {
  display: flex;
}

.list--decimal > li:before {
  content: counters(list-items, ".", decimal-leading-zero);
  counter-increment: list-items;
  color: var(--color-text-darker);
  opacity: .5;
  padding: .2em .5em 0 0;
  font-size: .85714em;
}

.pr {
  position: relative;
}

.mb05 {
  margin-bottom: .5rem;
}

.mb1 {
  margin-bottom: 1rem;
}

.td {
  color: var(--color-text-darker);
}

.ttu {
  text-transform: uppercase;
}

.db {
  display: block;
}

.df {
  display: flex;
}

.dif {
  display: inline-flex;
}

/*# sourceMappingURL=index.9d80e317.css.map */
