section.projects {
  & a {
    min-height: auto;
  }

  & .icon {
    border-radius: 3px;
    margin: 0.2rem 0.5rem 0 0.25rem;
    flex-shrink: 0;

    & img {
      border-radius: 3px;
    }
  }
}
