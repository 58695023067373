section.demos {
  & > .section__grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 0;
    margin: 0 -1rem;

    @media (min-width: 1228px) {
      margin: 0;
    }
  }
}

.section__grid__anchor {
  position: relative;
  display: block;

  & > figure {
    position: relative;
    background-color: var(--color-bg);
    transform: translate3d(0, 0, 0);

    & > figcaption {
      opacity: 0;
      position: absolute;
      background: var(--color-bg);
      inset: auto 0 0 0;
      padding: 0.25rem;
      font-size: .85714em;
      text-align: center;
      color: var(--color-text-darker);
      will-change: opacity;
    }
  }

  & .FollowingEye {
    position: absolute;
    width: 20%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 200ms;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus {
      & .section__grid__image {
        opacity: .25;
        transition: opacity 100ms linear;
      }
      & .FollowingEye {
        opacity: 1;
        transition: transform 200ms ease, opacity 100ms linear;
      }
      & figcaption {
        opacity: 1;
        transition: opacity 100ms linear;
      }
    }
  }
}

.section__grid__image {
  display: block;
  opacity: 1;
  background-color: var(--color-bg-lighter);
  will-change: opacity;
}
