.iframe-wrapper {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;

  & > iframe {
    background: #fff;
    display: block;
    width: 100%;
    height: 100%;
  }

  &.-loaded + .iframe-close-button {
    transform: translate3d(0, -50%, 0);
  }
}

.iframe-close-button {
  position: fixed;
  right: 1.5rem;
  top: 50%;
  transform: translate3d(200%, -50%, 0);
  width: 64px;
  height: 64px;
  background: black;
  border-radius: 50%;
  color: white;
  border: none;
  transition: transform 300ms cubic-bezier(0.1, 0.7, 0.6, 0.9);

  & > svg {
    display: block;
    margin: 0 auto;
  }
}

.iframe-loader {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  &.-ready { display: block; }
  &.-show > div { transform: translate3d(0, 0, 0); }
  &.-hide > div { transform: translate3d(0, calc(100vh + 26px), 0); }
}

.iframe-loader__layer {
  position: absolute;
  width: 100vw;
  height: 100vh;
  pointer-events: all;

  transform: translate3d(0, calc(-100vh - 26px), 0);
  transition: transform 400ms ease;
  will-change: transform;

  &.--waves {
    background-color: var(--color-sec);
    & > svg { fill: var(--color-sec); }
  }

  &.--info {
    background-color: var(--color-main);
    transition-delay: 200ms;
    display: flex;
    align-items: center;
    justify-content: center;
    & > svg { fill: var(--color-main); }
  }

  & > .loader-wave-top,
  & > .loader-wave-bottom {
    position: absolute;
    width: 100%;
    height: 26px;
  }

  & > .loader-wave-top { bottom: 100%; }
  & > .loader-wave-bottom { top: 100%; }
}

.iframe-loading-text {
  font-size: 1rem;
  font-weight: 900;
  margin: 0;
  text-align: center;
  color: var(--color-bg);
  opacity: 0;
  transition: opacity 300ms linear;

  & > .title,
  & > .description {
    display: inline-block;
    padding: .25rem 1rem;
  }

  & > .title {
    background: var(--color-bg);
    color: var(--color-text);
  }

  & > .description {
    background: var(--color-text);
    color: var(--color-bg);
  }
}
