.oss {
  & a {
    min-height: auto;
  }

  & .icon {
    width: 32px;
    height: 32px;
    border-radius: 3px;
    margin: 0.4rem 0.75rem 0 0.25rem;

    & img {
      border-radius: 3px;
    }
  }

  & .icon.emoji {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    background-color: var(--color-bg-lighter);
  }
}
