@import './lib/header';
@import './lib/social-nav';
@import './lib/section-demos';
@import './lib/section-oss';
@import './lib/section-projects';
@import './lib/projects-detail';
@import './lib/footer';

@font-face {
  font-family: 'Droid Sans Mono';
  font-display: swap;
  src: url(../fonts/droidsansmono.woff2) format('woff2');
}

:root {
  --color-bg: #1a1a1a;
  --color-bg-lighter: color-mix(in srgb, #1a1a1a, white 10%);
  --color-text: #f0f0f0;
  --color-text-darker: color-mix(in srgb, #f0f0f0 64%, black);
  --color-sec: rgba(161, 170, 243, 1);
  --color-main: hsl(172deg 100% 56%);
  --color-visited: hsl(172deg 25% 56%);
  --scrollbar-width: 16;

  font-size: 14px;
  line-height: 1.618;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  background-color: var(--color-main);
}

body {
  font-family: 'Droid Sans Mono', monospace;
  min-height: 100vh;
  background-color: var(--color-bg);
  color: var(--color-text);

  &.-prevent-scrolling {
    padding-right: var(--scrollbar-width);
    overflow: hidden;
  }
}

a {
  display: inline-block;
  min-width: 48px;
  min-height: 48px;
  color: var(--color-main);
  text-decoration: none;
  outline: none;

  & .-le {
    position: relative;
    display: inline-block;
    vertical-align: top;
    align-self: center;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--color-main);
      mix-blend-mode: difference;
      transform: translate3d(calc(-100% - 1px), 0, 0);
      transition: transform 200ms ease;
      will-change: transform;
    }
  }

  &:active,
  &:hover,
  &:focus {
    & .-le::before {
      transform: translate3d(0, 0, 0);
    }
  }

  &:visited {
    color: var(--color-visited);

    & .-le::before {
      background: var(--color-visited);
    }
  }

  &[target="_blank"] .-le {
    display: inline-flex;
    align-items: center;
  }
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 1.5rem;
}

h2 {
  font-size: 1rem;
}

ul { list-style: none; }

section {
  padding: 0 0 6rem;
}

svg { fill: currentColor; }

canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

 /* --------------------- */
.wrapper {
  max-width: calc(1200px + 2rem);
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.desc {
  font-size: 0.85714em;
  color: var(--color-text-darker);
}

.svg--external-icon {
  margin-left: 0.25em;
}

.section__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 1.2rem;
}

.list--decimal {
  counter-reset: list-items;
  & > li {
    display: flex;
    &::before {
      content: counters(list-items, ".", decimal-leading-zero);
      counter-increment: list-items;
      font-size: 0.85714em;
      padding: 0.2em 0.5em 0 0;
      color: var(--color-text-darker);
      opacity: 0.5;
    }
  }
}
.pr { position: relative }
.mb05 { margin-bottom: 0.5rem }
.mb1 { margin-bottom: 1rem }
.td { color: var(--color-text-darker) }
.ttu { text-transform: uppercase }
.db { display: block }
.df { display: flex }
.dif { display: inline-flex }
/* .-aic { align-items: center } */
